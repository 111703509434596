div#background-image {
  height: 80rem;
  width: 100%;
}

div.image-loader {
  background-image: url("/images/The-Consultants.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

div.bg-content {
  color: white;
  background-color: rgba(46, 46, 169, 0.65);
  position: absolute;
  bottom: 15rem;
  left: 0;
  right: 0;
  padding-top: 5rem;
  width: 100%;
  height: 30rem;
}

div.bg-content div.row h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
}

div.service {
  text-align: center;
}

div.service i {
  font-size: 5rem;
}

section#service-section {
  background-color: white;
}

section#technology-section {
  color: white;
  background-color: #328bc3;
}

section#portfolio-section {
  background-color: #dcdcdc;
}

section#about-section {
  background-color: #1c242e;
}
