/* Main Theme */
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Roboto Slab", serif;
}

.heading-text {
  padding-top: 5rem;
  padding-bottom: 2.5rem;
  display: inline-flex;
}
.heading-text h1 {
  padding-right: 5rem;
}

img.dev-img {
  height: 6rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
/* End Main Theme */

/* Navbar */
nav.navbar {
  font-size: 24;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  border-bottom: 10px solid gray;
  z-index: 100;
}

nav.navbar {
  text-align: center;
}

nav.navbar a.nav-link {
  font-family: "Roboto Slab", serif;
  font-weight: 400;
  font-size: 3rem;
  padding: 2rem;
  z-index: 100;
  color: #d8b310;
}
nav.navbar a.nav-link:hover {
  color: #328bc3;
}
/* End Navbar */

/* Footer */
#footer {
  font-size: 1.75rem;
  padding-top: 2.5rem;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  color: #d8b310;
}

footer p {
  text-align: center;
}
/* End Footer */

/*Social Icons*/
div.social-icons {
  font-size: 2em;
  text-align: center;
}

i.icon-linkedin:hover {
  color: #0e76a8;
}

i.icon-twitter:hover {
  color: #00a0d1;
}

i.icon-google:hover {
  color: #db4a39;
}

i.icon-facebook:hover {
  color: #3b5998;
}
/*End Social Icons*/

/* Colors */
.soft-red {
  color: #d42828;
}

.soft-blue {
  color: #2e2ea9;
}

.soft-green {
  color: #38a238;
}
/* End Colors */

.portfolio-caption {
  font-size: 1.25em;
  font-family: "Roboto Slab", serif;
  font-weight: 800;
}
.logo-height {
  max-height: 100px;
}

/* Racing Pages */

.racing-page {
  display: flex;
}

/* Header */

.racing-header {
}

#racing-body {
  margin-top: 100px;
}

/* Footer */

@media only screen and (max-width: 600px) {
  .racing-footer {
    position: relative;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, 0.9);
    color: #d8b310;
  }
}

@media only screen and (min-width: 600px) {
  .racing-footer {
    position: static;
    width: 100%;
    height: 100px;
    bottom: 0;
    left: 0;
    font-size: 1rem;
    background-color: #2c3136;
    color: #d8b310;
  }
}

/* Events */
.horizontal-scroll {
  overflow-x: scroll;
  margin: 120px 20px 20px 20px;
  padding: 10px;
}

.events {
  display: flex;
}

.event {
  position: relative;
  max-width: 500px;
  padding: 10px;
}
.event p {
  padding: 0;
  margin: 0;
}
.event h6 {
  padding: 1rem;
  margin: 1rem;
  background: gray;
  color: white;
  border-radius: 3px;
}

.race-img {
  height: 15rem;
  width: 20rem;
}

.event button {
  position: absolute;
  bottom: 0;
}
/* End Races */

/* Cart */
.cart-product {
  border: solid thin black;
  margin-bottom: 5px;
  padding: 10px;
}

.cart-total-summary {
  margin-top: 20px;
  padding: 20px;
  height: 250px;
  border: solid thin black;
}
/* End Cart */

/* Products */

.products {
  width: 100vw;
  flex-wrap: wrap;
}

.product {
  width: 400px;
}

/* End Products */

/* FLEX */

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.flex-column div {
  display: flex;
  justify-content: space-between;
}

/* END FLEX */
